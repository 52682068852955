
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
// import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import * as Yup from 'yup'
import localforage from 'localforage'
import { isEmpty } from 'lodash'
// import ApiService from '@/core/services/ApiService'
import { buildLocalResourceUrl } from '@/core/mc-admin-helpers/activity-template'
export default defineComponent({
  name: 'sign-in',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup () {
    const store = useStore()
    // const router = useRouter()
    const loginForm = ref()
    const submitButton = ref<HTMLButtonElement | null>(null)

    const isLoginPending = ref(false)
    // Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label('Email'),
      password: Yup.string().min(4).required().label('Password')
    })
    const loginError = ref('')

    // Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      if (submitButton.value) {
                // eslint-disable-next-line
                submitButton.value!.disabled = true;
                // Activate indicator
                submitButton.value.setAttribute('data-kt-indicator', 'on')
      }
      isLoginPending.value = true
      loginError.value = ''
      // Send login request
      store.dispatch(Actions.LOGIN, values).then(() => {
        localforage.getItem('intended').then((name: any) => {
          if (isEmpty(name)) {
            // router.replace({ name: 'console' })
            window.location.href = '/console'
            return
          }
          window.location.href = name
          // router.replace({ name: name })
        })
        isLoginPending.value = false
      }).catch((response) => {
        isLoginPending.value = false
        if (submitButton.value) {
              // eslint-disable-next-line
              submitButton.value!.disabled = true;
              // Activate indicator
              submitButton.value.setAttribute('data-kt-indicator', 'off')
        }
        console.log('response', response)
        loginError.value = response.data.error.message
        // Swal.fire({
        //   html: response.data.error.message,
        //   icon: 'error',
        //   buttonsStyling: false,
        //   confirmButtonText: 'Okay',
        //   customClass: {
        //     confirmButton: 'btn fw-bold btn-light-danger'
        //   }
        // })
      })

      // Deactivate indicator
      submitButton.value?.removeAttribute('data-kt-indicator')
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
    }

    return {
      onSubmitLogin,
      isLoginPending,
      loginForm,
      login,
      submitButton,
      loginError,
      buildLocalResourceUrl
    }
  }
})
