import moment from 'moment'
import dayjs from 'dayjs'
import axios from 'axios'
// import axios from 'axios'

export const formatActivitiesTableDate = (apiDate: string): string => {
  return moment(String(apiDate)).format('DD/MM/YYYY')
}

export const formatLastActionDatatable = (activityTemplate): string => {
  let prefix = 'Added'
  let dateAction = formatActivitiesTableDate(activityTemplate.createdAt)
  if (activityTemplate.createdAt !== activityTemplate.updatedAt && activityTemplate.updatedAt !== null) {
    prefix = 'Modified'
    dateAction = formatActivitiesTableDate(activityTemplate.updatedAt)
  }
  return prefix + ' ' + dateAction
}

export const formatSecondsToMinutes = (seconds: number): number => {
  return Math.floor(seconds / 60)
}

export const buildTemplateImageUrl = (templateUuid: string, etag: string, size: number = 420) => {
  return process.env.VUE_APP_API_URL + '/api/v1/activity-template/' + templateUuid + '/image?etag=' + etag + '&size=' + size
}

export const buildTemplateImageUri = (attachmentUuid: string | null, company: string, size: string = 'size420') => {
  if (!attachmentUuid || attachmentUuid === '' || attachmentUuid === 'null') {
    return `${window.location.origin}/mc-admin-media/png/no-image-placeholder.png`
  }
  return process.env.VUE_APP_API_URL + '/api/v1/attachment/' + attachmentUuid + '?size=' + size + '&company=' + company
}

export async function getImageUrl (attachmentUuid: string | null, size: string = 'size420'): Promise<string> {
  if (attachmentUuid === null || attachmentUuid === '') {
    return `${window.location.origin}/mc-admin-media/png/no-image-placeholder.png`
  }

  try {
    // Make the authenticated request to the API backend
    const response = await axios.get(process.env.VUE_APP_API_URL + '/api/v1/attachment/' + attachmentUuid + '?size=' + size, {
      headers: {
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNGNjZGNhNDljMWUxYzE3NDBlYmE2MmZjYmZhNjg1YTFhNWExNzg0YzBhNTMyNzM2YTY1Y2Q0NjVhNzhjMGNmYzQ4NTI1OWZmNTQzMmFkOWMiLCJpYXQiOjE2NzE1MjIzNjkuMDA0MTkzLCJuYmYiOjE2NzE1MjIzNjkuMDA0MTk1LCJleHAiOjE2NzE2MDg3NjguOTg1MTUxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ESvqrdiyKCVdI57Y7NBz3cocAQIO4i6NWqw0wY3xeGcyeWG5MFoj_R6txFjFTgKh72sDxjYgLhAOKmphU10aT4ACyrdq3UAiutG4hXqySlf4BxU4SKSuMFyAEZcmaxDZAGumm9_lJaMCkLWHHrJ3eCVu0Kmda8xhqzshUGYdikksHLMUZsXGmLD6TTG7Xe9wgbxeVZYy1PRVL6Ud3GTZtzsxzyR8tCxBSuGKyNi4I-Pi4DN833r9q0EW19XttcnKodGs_9Un7lBXCD0ZSgy4Kv33ZOY2enCCrELL8AxnXkSFoE_Y01G5NyWlHrieoJr8r4FZcn01QkR-TbJJz6e7cz7nko_Etce8pQ5My6NjEMv3gSE7fb7ir0QTyBc8G91RlbBR3cKXvbN9C2SGtgxRhU2-CfGvKfHiR4BAenAfBjTeHu9FFUERHAnWOv3v_McTgQDA5ECpX67ItEtP-_Lpqu6jJnJu5Oo37YvDUVEQPsBju1wvMD_Iy5I7Js4H48k_tStuE-8--agN9hChCA73o5uI8WBxRpeHIFMse87RlInzlxRkHXLUGwUtjET0ByUUWSnQ9oeOohfgPpqpchukYroPsIJy0BGdDe_b8KXEKm2INWZ1zJQqqiBWTpGq-juUuWf7vQcVw0o5m4kaigYEiRpdXhUZ1gUsYw5jJlMdts4'
      }
    })

    // The API backend should return a 307 redirect in the response
    // Get the redirect URL from the response
    // Return the image URL
    // return response.headers.location
    return response.data.payload.uri
  } catch (error) {
    console.log('Failed retrieving image url: ', error)
    // Handle the error if the request fails
  }

  // fallback
  return `${window.location.origin}/mc-admin-media/png/no-image-placeholder.png`
}

export const buildTemplateImageUris = (attachmentUuid: string | null, size: string = 'size420') => {
  if (attachmentUuid === null || attachmentUuid === '') {
    return `${window.location.origin}/mc-admin-media/png/no-image-placeholder.png`
  }

  return new Promise((resolve, reject) => {
    axios.get(process.env.VUE_APP_API_URL + '/api/v1/attachment/' + attachmentUuid + '?size=' + size, { method: 'GET', responseType: 'json', headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMWVjZWYzMDJlOTUwZWVkMzliYzZmNTVjNzVjNmM5OTYxOTY1NjdhMmMzMDA4MzY5YWVmMjA0NDMzOGExZjIzMTEyN2Y2YWIzZGIzNTMxOWEiLCJpYXQiOjE2NzE0MzQ2MjguMTUyNTc2LCJuYmYiOjE2NzE0MzQ2MjguMTUyNTc3LCJleHAiOjE2NzE1MjEwMjguMTI2NDI2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.p6B48QHwOQBdxPy7_Q2UuoigjrzMmRIoZVdaMds9QNa7v5qYS4xPttfNbF2mOlTYSFbFMlLbStf7fw-xb8PMcQPgKstK60XyUe0ZzXwb68hR7UaFHsVEZ05Qs2kZ6SFmga1PLBQaJ9gg8oPjE64xQZ19fCl0lFeWyYi5czipbk8gYKOXkj5KI3Y0DFUNDgR1Gjwv6uFE_yKLV2FE0Ws_J5UqpA3rjRktP8gdj985pMcxWgp9v8ix8pR-Ksw--2nP-JvMONptU7_LEG-S7JFSqBpHQaR-FNQO66V_LLYMIc89_w72mTKd2eqLZQSjDBT7UHI2xlXGAw9IRbClmorYI3Jr7xoX4boUMPrTnDC5xF6ixsni1emUMHGFt3VPqHp1EN2fgA-2H2gduiTPF0BFhFKizPnVwL129MW6aL2law1H4mxdV2crnbO9K9NuwYcZed7a4b1pVqnSUjBHezOuSy1njLEt9wqV_pe7pwws7qhGq1MN4780O9bidk7R3ADJFdtdV64SrY5in18bfTLoMgCPPeYcbhAzQ2zuBwmEEagiUkqEVDHxyP5zLM_rAJQ49MYBTC0EwhoMzOCwuIcWZE5fSieVf9iy3fuFmrik5bpbOJ6Zu80vglhCsf6a3SBCfEF3YjtfxOcBR8h_8CitzNX14RylkeZeUjBVR7XUsOA' } }).then((response: any) => {
      return response.data.payload.uri
    }).catch((e) => {
      console.log('Failed building template image URIs: ', e)
    })
  })
}

export const buildTemplateFileUrl = (templateUuid: string, attachmentUuid: string) => {
  return process.env.VUE_APP_API_URL + '/api/v1/activity-template/' + templateUuid + '/file/' + attachmentUuid
}
export const buildTemplateFileUri = (
  attachmentUuid: string,
  company: string,
  imageUri: string | null = null
): string | null => {
  if (attachmentUuid && attachmentUuid !== 'undefined') {
    return process.env.VUE_APP_API_URL + '/api/v1/attachment/' + attachmentUuid + '?company=' + company
  }
  return imageUri
}

export const buildApiResourceUrl = (resource: string) => {
  return process.env.VUE_APP_API_URL + '/api/v1/' + resource
}

export const buildLocalResourceUrl = (resourcePath: string) => {
  return `${window.location.origin}/${resourcePath}`
}

export const formatHumanDate = (dateString) => {
  const date = dayjs(dateString)
  return date.format('MMM D, HH:mm')
}

export const getEtagForTemplate = (imageMetadata) => {
  let imageMetadataObject = {} as any
  try {
    imageMetadataObject = JSON.parse(imageMetadata)
    return imageMetadataObject.etag
  } catch (e) {
    return 'no-image-placeholder'
  }
}
